import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_bootstrapvue_f9ee0b16 from 'nuxt_plugin_bootstrapvue_f9ee0b16' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_fontawesome_3b6c4908 from 'nuxt_plugin_fontawesome_3b6c4908' // Source: ./fontawesome.js (mode: 'all')
import nuxt_plugin_bodebug_0cce636e from 'nuxt_plugin_bodebug_0cce636e' // Source: ../plugins/bo-debug (mode: 'client')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_borepository_2fb12d14 from 'nuxt_plugin_borepository_2fb12d14' // Source: ../plugins/bo-repository (mode: 'all')
import nuxt_plugin_bocomponents_be825640 from 'nuxt_plugin_bocomponents_be825640' // Source: ../plugins/bo-components (mode: 'all')
import nuxt_plugin_bomodals_496a2230 from 'nuxt_plugin_bomodals_496a2230' // Source: ../plugins/bo-modals (mode: 'client')
import nuxt_plugin_bohtmleditor_5a92f576 from 'nuxt_plugin_bohtmleditor_5a92f576' // Source: ../plugins/bo-html-editor (mode: 'client')
import nuxt_plugin_bodirective_c9c15576 from 'nuxt_plugin_bodirective_c9c15576' // Source: ../plugins/bo-directive (mode: 'client')
import nuxt_plugin_bohelpers_6a7c0e0a from 'nuxt_plugin_bohelpers_6a7c0e0a' // Source: ../plugins/bo-helpers (mode: 'all')
import nuxt_plugin_bogates_0c7cf604 from 'nuxt_plugin_bogates_0c7cf604' // Source: ../plugins/bo-gates (mode: 'all')
import nuxt_plugin_boworkers_14bba1aa from 'nuxt_plugin_boworkers_14bba1aa' // Source: ../plugins/bo-workers (mode: 'client')
import nuxt_plugin_bodevice_3989e520 from 'nuxt_plugin_bodevice_3989e520' // Source: ../plugins/bo-device (mode: 'all')
import nuxt_plugin_auth_7543862a from 'nuxt_plugin_auth_7543862a' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_boauth_74302dc5 from 'nuxt_plugin_boauth_74302dc5' // Source: ../plugins/bo-auth.js (mode: 'all')
import nuxt_plugin_echo_297f4c8d from 'nuxt_plugin_echo_297f4c8d' // Source: ./echo.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"BO","htmlAttrs":{"lang":"en"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"app-version-id","content":"augiklvv"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico?v=augiklvv"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_bootstrapvue_f9ee0b16 === 'function') {
    await nuxt_plugin_bootstrapvue_f9ee0b16(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_3b6c4908 === 'function') {
    await nuxt_plugin_fontawesome_3b6c4908(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bodebug_0cce636e === 'function') {
    await nuxt_plugin_bodebug_0cce636e(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_borepository_2fb12d14 === 'function') {
    await nuxt_plugin_borepository_2fb12d14(app.context, inject)
  }

  if (typeof nuxt_plugin_bocomponents_be825640 === 'function') {
    await nuxt_plugin_bocomponents_be825640(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bomodals_496a2230 === 'function') {
    await nuxt_plugin_bomodals_496a2230(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bohtmleditor_5a92f576 === 'function') {
    await nuxt_plugin_bohtmleditor_5a92f576(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bodirective_c9c15576 === 'function') {
    await nuxt_plugin_bodirective_c9c15576(app.context, inject)
  }

  if (typeof nuxt_plugin_bohelpers_6a7c0e0a === 'function') {
    await nuxt_plugin_bohelpers_6a7c0e0a(app.context, inject)
  }

  if (typeof nuxt_plugin_bogates_0c7cf604 === 'function') {
    await nuxt_plugin_bogates_0c7cf604(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_boworkers_14bba1aa === 'function') {
    await nuxt_plugin_boworkers_14bba1aa(app.context, inject)
  }

  if (typeof nuxt_plugin_bodevice_3989e520 === 'function') {
    await nuxt_plugin_bodevice_3989e520(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_7543862a === 'function') {
    await nuxt_plugin_auth_7543862a(app.context, inject)
  }

  if (typeof nuxt_plugin_boauth_74302dc5 === 'function') {
    await nuxt_plugin_boauth_74302dc5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echo_297f4c8d === 'function') {
    await nuxt_plugin_echo_297f4c8d(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
